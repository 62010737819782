import React from 'react'
import About from '../components/about/About'
import AdSense from 'react-adsense'
import AnyVideos from '../components/SuggestedVideos/AnyVideos/AnyVideos'
import { BlogData } from '../contexts/blogData/blogData'
import { CampingData } from '../contexts/campingData/campingData'
import CategoryLinkSelector from '../components/Selectors/CategoryLinkSelector/CategoryLinkSelector'
import Hero from '../components/Hero/Hero'
import { LodgingData } from '../contexts/lodgingData/lodgingData'
import MediaQuery from '../components/sharedComponents/MediaQuery/MediaQuery'
import RecentPosts from '../components/recentPosts/RecentPosts'
import SEO from '../components/seo'
import SideBar from '../components/sideBar/SideBar'
import TwoColumnLayout from '../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../contexts/videoData/videoData'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const IndexPage = () => {
  const matchPoints = MediaQuery(queries)
  return (
    <>
      <SEO
        title="Camping & Recreation | Reviews & Information"
        description="Welcome to the Cactus Atlas! We provide reviews and information
      about locations in the American Southwest."
        url="https://cactusatlas.com/"
        type="article"
      />
      <TwoColumnLayout>
        <BlogData>
          <CampingData>
            <LodgingData>
              <VideoData>
                <section>
                  <Hero />
                  <RecentPosts />
                </section>
                <SideBar>
                  <About />
                  <div style={{ marginTop: '40px' }}>
                    <AnyVideos />
                  </div>
                  <CategoryLinkSelector />
                  {matchPoints && matchPoints.desktop ? (
                    <AdSense.Google
                      client="ca-pub-1156710604979342"
                      slot="5624519744"
                      style={{
                        display: 'inline-block',
                        width: '300px',
                        height: '250px',
                        position: 'sticky',
                        top: '184px',
                      }}
                    />
                  ) : null}
                </SideBar>
              </VideoData>
            </LodgingData>
          </CampingData>
        </BlogData>
      </TwoColumnLayout>
    </>
  )
}

export default IndexPage
