import React, { useContext } from 'react'
import ArticleButton from '../sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../components/sharedComponents/ArticleTitle/ArticleTitle'
import BlogContext from '../../contexts/blogData/blogData'
import CampingContext from '../../contexts/campingData/campingData'
import { getFluidImage } from '../../helpers/dynamicGatsbyImage'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import LodgingContext from '../../contexts/lodgingData/lodgingData'
import NearbyCampingLodging from '../NearbyCampingLodging/NearbyCampingLodging'
import { orderBy } from 'lodash'
import styles from './hero.module.css'

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { ne: "md" } }) {
          nodes {
            childImageSharp {
              id
              fluid(maxWidth: 1210) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
            extension
          }
        }
      }
    `
  )

  const post = orderBy(
    [
      ...useContext(LodgingContext),
      ...useContext(CampingContext),
      ...useContext(BlogContext),
    ],
    ['date'],
    ['desc']
  )[0]
  const {
    type,
    title,
    summary,
    videoId,
    alt,
    slug,
    camping,
    place_id,
    displayName,
  } = post

  const fluidImg = getFluidImage(post, data.allFile.nodes)
  return (
    <div>
      <ArticleTitle>{title}</ArticleTitle>
      <div className={styles.imageContainer}>
        <Img fluid={fluidImg} alt={alt} />
      </div>
      <p className={styles.heroCaption}>{summary}</p>

      <div className={styles.articleLinks}>
        <ArticleButton
          icon="read"
          url={`/${type}/${slug}`}
          color="#d45c42"
          text="Read More ..."
        />
        <ArticleButton
          icon="info"
          url={`/information/${slug}`}
          color="#b63d2b"
          text="Information"
        />
        <ArticleButton
          icon="video"
          url={`/videos`}
          videoId={videoId}
          color="#9f211c"
          text="Watch Our Video"
        />
      </div>
      {camping && camping[0] ? (
        <NearbyCampingLodging
          destination_id={place_id}
          campId={camping[0]}
          name={displayName}
        />
      ) : null}
    </div>
  )
}

export default Hero
