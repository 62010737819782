import React from 'react'
import ArticleButton from '../sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../components/sharedComponents/ArticleTitle/ArticleTitle'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styles from './blogPost.module.css'

const BlogPost = ({ hero, post, currentCategory }) => {
  const { type, alt, title, summary, videoId, slug } = post
  return (
    <div className={styles.articleContainer}>
      <div className={styles.imageTitleContainer}>
        <div className={styles.imageContainer}>
          <Img fluid={hero} alt={alt} />
        </div>
        <div className={styles.titleContainer}>
          <ArticleTitle>{title}</ArticleTitle>
        </div>
      </div>
      <p className={styles.recentCaption}>{summary}</p>

      <div className={styles.articleLinks}>
        <ArticleButton
          icon="read"
          url={`/${type}/${slug}`}
          color="#d45c42"
          text="Read More ..."
          currentCategory={currentCategory}
        />
        <ArticleButton
          icon="info"
          url={`/information/${slug}`}
          color="#b63d2b"
          text="Information"
        />
        <ArticleButton
          icon="video"
          url={`/videos`}
          videoId={videoId}
          color="#9f211c"
          text="Watch Our Video"
        />
      </div>
    </div>
  )
}

BlogPost.propTypes = {
  post: PropTypes.object,
  currentCategory: PropTypes.string,
  hero: PropTypes.object,
}

export default BlogPost
