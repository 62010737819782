export const computeDistance = (
  distanceMatrix,
  originPlaceID,
  destinationPlaceID
) => {
  const originIndex = distanceMatrix.origin_ids.findIndex(
    originId => originId === originPlaceID
  )
  const destinationIndex = distanceMatrix.destination_ids.findIndex(
    destinationId => destinationId === destinationPlaceID
  )
  if (
    distanceMatrix.rows[originIndex].elements[destinationIndex].status === 'OK'
  ) {
    return distanceMatrix.rows[originIndex].elements[destinationIndex].distance
      .text
  }

  return
}

export const computeFilteredCampingAttributes = (
  campgroundToLocationDistance,
  {
    rates,
    isAccessible,
    max_vehicle_length,
    toilets,
    isPetsAllowed,
    isCampfireAllowed,
    isDrinkableWater,
  }
) => {
  const campgroundOptionalAttributes = [
    {
      value: campgroundToLocationDistance,
      icon: 'distance',
      text: `${campgroundToLocationDistance} away`,
    },
    { value: rates, icon: 'rates', text: rates },
    { value: isAccessible, icon: 'accessiblity', text: 'Accessible Sites' },
    { value: max_vehicle_length, icon: 'length', text: max_vehicle_length },
    { value: toilets, icon: 'restrooms', text: toilets },
    { value: isPetsAllowed, icon: 'pets', text: 'Pets Allowed' },
    { value: isCampfireAllowed, icon: 'campfire', text: 'Campfires Allowed' },
    { value: isDrinkableWater, icon: 'water', text: 'Drinkable Water' },
  ]

  return campgroundOptionalAttributes
    .filter(attribute => attribute.value)
    .slice(0, 5)
}
