import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import CampingContext from '../../../contexts/campingData/campingData'
import { computeUniqueCategories } from '../../../helpers/helpers'
import { Link } from 'gatsby'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import PropTypes from 'prop-types'
import styles from './categoryLinkSelector.module.css'

const CategoryLink = ({ category, selectedCategory, page }) => {
  return (
    <li>
      <Link
        className={`${styles.categoryLink} ${
          selectedCategory === category.value ? styles.activeCategory : null
        }`}
        to={page}
        state={{ category: category.value }}
      >
        {category.display}
      </Link>
    </li>
  )
}

const CategoryLinkSelector = ({ currentCategory }) => {
  const adventureCategories = computeUniqueCategories(useContext(BlogContext))
  const adventures = adventureCategories.map((category, index) => (
    <CategoryLink
      key={index}
      category={category}
      selectedCategory={currentCategory}
      page="/adventures"
    />
  ))

  const campgroundCategories = computeUniqueCategories(
    useContext(CampingContext)
  ).filter(category => category.value !== 'camping')
  const campgrounds = campgroundCategories.map((category, index) => (
    <CategoryLink
      key={index}
      category={category}
      selectedCategory={currentCategory}
      page="/camping"
    />
  ))

  const lodgingCategories = computeUniqueCategories(
    useContext(LodgingContext)
  ).filter(category => category.value !== 'lodging')
  const lodging = lodgingCategories.map((category, index) => (
    <CategoryLink
      key={index}
      category={category}
      selectedCategory={currentCategory}
      page="/lodging"
    />
  ))

  return (
    <div>
      <h3 className={styles.categoriesHeader}>Adventures</h3>
      <ul>{adventures}</ul>
      <h3 className={styles.categoriesHeader}>Campgrounds</h3>
      <ul>{campgrounds}</ul>
      <h3 className={styles.categoriesHeader}>Lodging</h3>
      <ul>{lodging}</ul>
    </div>
  )
}

CategoryLink.propTypes = {
  category: PropTypes.object,
  selectedCategory: PropTypes.string,
  page: PropTypes.string,
}

CategoryLinkSelector.propTypes = {
  currentCategory: PropTypes.string,
}
export default CategoryLinkSelector
