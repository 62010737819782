import React from 'react'
import {
  CampingAttributeWithExternalLink,
  CampingAttributeWithInternalLink,
} from '../CampingAttribute/CampingAttribute'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './nearbyCampingOneColumn.module.css'

export const NearbyCampingOneColumn = ({ nearbyCampingData }) => {
  const { videoThumb, videoId, google_map_link, slug } = nearbyCampingData
  return (
    <div className={styles.NearbyCampingOneColumn}>
      <div>
        <Link to={`/videos`} state={{ videoId: videoId }}>
          <Img className={styles.videoThumb} fluid={videoThumb} />
        </Link>
      </div>
      <div className={styles.attributeRow}>
        <CampingAttributeWithExternalLink
          icon="directions"
          link={google_map_link}
          text="Directions"
        />
        <CampingAttributeWithInternalLink
          icon="youtube"
          link={`/videos`}
          videoId={videoId}
          text="Watch Video Tour"
        />
        <CampingAttributeWithInternalLink
          icon="information"
          link={`/information/${slug}`}
          text="More Information"
        />
      </div>
    </div>
  )
}

NearbyCampingOneColumn.propTypes = {
  nearbyCampingData: PropTypes.object,
}

export default NearbyCampingOneColumn
