import React from 'react'
import { IconContext } from 'react-icons'
import { Link } from 'gatsby'
import {
  FaBookReader,
  FaCalendarAlt,
  FaCarSide,
  FaCommentDollar,
  FaDog,
  FaMap,
  FaRestroom,
  FaShuttleVan,
  FaWheelchair,
  FaYoutube,
} from 'react-icons/fa'

import { IoIosBonfire, IoIosWater } from 'react-icons/io'
import PropTypes from 'prop-types'
import styles from './campingAttributes.module.css'

export const getIconComponent = icon => {
  let fa
  switch (icon) {
    case 'distance':
      fa = <FaCarSide />
      break
    case 'rates':
      fa = <FaCommentDollar />
      break
    case 'reservation':
      fa = <FaCalendarAlt />
      break
    case 'accessiblity':
      fa = <FaWheelchair />
      break
    case 'length':
      fa = <FaShuttleVan />
      break
    case 'restrooms':
      fa = <FaRestroom />
      break
    case 'campfire':
      fa = <IoIosBonfire />
      break
    case 'pets':
      fa = <FaDog />
      break
    case 'directions':
      fa = <FaMap />
      break
    case 'youtube':
      fa = <FaYoutube />
      break
    case 'information':
      fa = <FaBookReader />
      break
    case 'water':
      fa = <IoIosWater />
      break
    default:
      fa = null
  }
  return fa
}

export const CampingAttribute = ({ attribute }) => {
  return (
    <div className={styles.campingAttribute}>
      <IconContext.Provider value={{ color: '#9b9b9b', size: '40px' }}>
        {getIconComponent(attribute.icon)}
      </IconContext.Provider>
      <div className={styles.campingAttributeText}>{attribute.text}</div>
    </div>
  )
}

export const ReservableCampground = ({ reservable }) => {
  return (
    <div className={styles.campingAttribute}>
      <IconContext.Provider value={{ color: '#9b9b9b', size: '40px' }}>
        {getIconComponent('reservation')}
      </IconContext.Provider>
      <div className={styles.campingAttributeText}>
        {reservable ? (
          <a
            className={styles.link}
            href={reservable}
            target="_blank"
            rel="noopener noreferrer"
          >
            Reservable
          </a>
        ) : (
          'Not Reservable'
        )}
      </div>
    </div>
  )
}

export const CampingAttributeWithExternalLink = ({ icon, link, text }) => {
  return (
    <div className={styles.campingAttribute}>
      <IconContext.Provider value={{ color: '#9b9b9b', size: '40px' }}>
        {getIconComponent(icon)}
      </IconContext.Provider>
      <div className={styles.campingAttributeText}>
        <a
          className={styles.link}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      </div>
    </div>
  )
}

export const CampingAttributeWithInternalLink = ({
  icon,
  link,
  text,
  videoId,
}) => {
  return (
    <div className={styles.campingAttribute}>
      <IconContext.Provider value={{ color: '#9b9b9b', size: '40px' }}>
        {getIconComponent(icon)}
      </IconContext.Provider>
      <div className={styles.campingAttributeText}>
        <Link className={styles.link} to={link} state={{ videoId: videoId }}>
          {text}
        </Link>
      </div>
    </div>
  )
}

CampingAttribute.propTypes = {
  attribute: PropTypes.object,
}

ReservableCampground.propTypes = {
  reservable: PropTypes.string,
}

CampingAttributeWithExternalLink.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
}

CampingAttributeWithInternalLink.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
  videoId: PropTypes.string,
}
