import React, { useContext } from 'react'
import CampingContext from '../../contexts/campingData/campingData'
import { computeDistance, computeFilteredCampingAttributes } from './helpers'
import MediaQuery from '../sharedComponents/MediaQuery/MediaQuery'
import { useStaticQuery, graphql } from 'gatsby'
import NearbyCampingOneColumn from './layouts/NearbyCampingOneColumn'
import NearbyCampingTwoColumn from './layouts/NearbyCampingTwoColumn'
import NearbyCampingThreeColumn from './layouts/NearbyCampingThreeColumn'
import NearbyCampingFourColumn from './layouts/NearbyCampingFourColumn'
import VideoContext from '../../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import styles from './nearbyCampingLodging.module.css'

const queries = {
  mobile: '(max-width: 374px)',
  tablet: '(min-width: 375px) and (max-width: 599px)',
  tablet2: '(min-width:600px) and (max-width: 799px)',
  tablet3: '(min-width: 800px) and (max-width: 999px)',
  desktop: '(min-width: 1000px) and (max-width: 1229px)',
  desktop2: '(min-width: 1230px)',
}

const OvernightLocation = ({ campingData, videoData, destination_id }) => {
  const matchPoints = MediaQuery(queries)
  const data = useStaticQuery(
    graphql`
      query {
        allDistanceMatrixData {
          edges {
            node {
              distanceMatrix {
                rows {
                  elements {
                    distance {
                      text
                      value
                    }
                    status
                  }
                }
                destination_ids
                origin_ids
              }
            }
          }
        }
      }
    `
  )

  const distanceMatrix = data.allDistanceMatrixData.edges[0].node.distanceMatrix

  const campgroundToLocationDistance = computeDistance(
    distanceMatrix,
    campingData.placeId,
    destination_id
  )

  const { google_map_link, reservation_page, slug } = campingData

  const filteredCampgroundAttributes = computeFilteredCampingAttributes(
    campgroundToLocationDistance,
    campingData
  )

  const nearbyCampingData = {
    videoId: videoData.videoId,
    videoThumb: videoData.localThumbnail.childImageSharp.fluid,
    filteredCampgroundAttributes,
    reservation_page,
    google_map_link,
    slug,
  }

  return (
    <>
      {matchPoints && matchPoints.mobile ? (
        <NearbyCampingOneColumn nearbyCampingData={nearbyCampingData} />
      ) : null}
      {matchPoints && matchPoints.tablet ? (
        <NearbyCampingTwoColumn nearbyCampingData={nearbyCampingData} />
      ) : null}
      {matchPoints && matchPoints.tablet2 ? (
        <NearbyCampingThreeColumn nearbyCampingData={nearbyCampingData} />
      ) : null}
      {matchPoints && matchPoints.tablet3 ? (
        <NearbyCampingFourColumn nearbyCampingData={nearbyCampingData} />
      ) : null}
      {matchPoints && matchPoints.desktop ? (
        <NearbyCampingThreeColumn nearbyCampingData={nearbyCampingData} />
      ) : null}
      {matchPoints && matchPoints.desktop2 ? (
        <NearbyCampingFourColumn nearbyCampingData={nearbyCampingData} />
      ) : null}
    </>
  )
}

const NearbyCampingLodging = ({ destination_id, campId, name }) => {
  const allCampingData = useContext(CampingContext)
  const allVideos = useContext(VideoContext)

  const videoData = {
    ...allVideos
      .filter(video => video.node.videoId === campId)
      .map(video => video.node)[0],
  }
  const campingData = {
    ...allCampingData.filter(review => review.videoId === campId)[0],
  }

  return (
    <div>
      <h3 className={styles.nearbyHeader}>Camping Near {name}</h3>
      <div className={styles.nearbyOvernightLocation}>
        <OvernightLocation
          campingData={campingData}
          videoData={videoData}
          destination_id={destination_id}
        />
      </div>
    </div>
  )
}

NearbyCampingLodging.propTypes = {
  campId: PropTypes.string,
  destination_id: PropTypes.string,
  name: PropTypes.string,
}

OvernightLocation.propTypes = {
  campId: PropTypes.string,
  destination_id: PropTypes.string,
  campingData: PropTypes.object,
  videoData: PropTypes.object,
}
export default NearbyCampingLodging
