import React from 'react'
import {
  CampingAttribute,
  CampingAttributeWithExternalLink,
  CampingAttributeWithInternalLink,
  ReservableCampground,
} from '../CampingAttribute/CampingAttribute'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './nearbyCampingThreeColumn.module.css'

export const NearbyCampingThreeColumn = ({ nearbyCampingData }) => {
  const {
    videoThumb,
    videoId,
    filteredCampgroundAttributes,
    reservation_page,
    google_map_link,
    slug,
  } = nearbyCampingData
  return (
    <div>
      <div className={styles.nearbyCampingThreeColumn}>
        <div className={styles.attributeRow}>
          <Link to={`/videos`} state={{ videoId: videoId }}>
            <Img className={styles.videoThumb} fluid={videoThumb} />
          </Link>
        </div>
        <div className={styles.attributeRow}>
          <CampingAttribute attribute={filteredCampgroundAttributes[0]} />
          <CampingAttribute attribute={filteredCampgroundAttributes[1]} />
          <ReservableCampground reservable={reservation_page} />
        </div>
        <div className={styles.attributeRow}>
          <CampingAttribute attribute={filteredCampgroundAttributes[2]} />
          <CampingAttribute attribute={filteredCampgroundAttributes[3]} />
          <CampingAttribute attribute={filteredCampgroundAttributes[4]} />
        </div>
      </div>
      <div className={styles.linksRow}>
        <div className={styles.attributeLink}>
          <CampingAttributeWithExternalLink
            icon="directions"
            link={google_map_link}
            text="Directions"
          />
        </div>
        <div className={styles.attributeLink}>
          <CampingAttributeWithInternalLink
            icon="youtube"
            link={`/videos`}
            videoId={videoId}
            text="Watch Video Tour"
          />
        </div>
        <div className={styles.attributeLink}>
          <CampingAttributeWithInternalLink
            icon="information"
            link={`/information/${slug}`}
            text="More Information"
          />
        </div>
      </div>
    </div>
  )
}

NearbyCampingThreeColumn.propTypes = {
  nearbyCampingData: PropTypes.object,
}

export default NearbyCampingThreeColumn
