import React from 'react'
import {
  CampingAttribute,
  CampingAttributeWithExternalLink,
  CampingAttributeWithInternalLink,
  ReservableCampground,
} from '../CampingAttribute/CampingAttribute'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './nearbyCampingFourColumn.module.css'

export const NearbyCampingFourColumn = ({ nearbyCampingData }) => {
  const {
    videoThumb,
    videoId,
    filteredCampgroundAttributes,
    reservation_page,
    google_map_link,
    slug,
  } = nearbyCampingData
  return (
    <div className={styles.nearbyCampingFourColumn}>
      <div className={styles.attributeRow}>
        <Link to={`/videos`} state={{ videoId: videoId }}>
          <Img fluid={videoThumb} />
        </Link>
      </div>
      <div className={styles.attributeRow}>
        <CampingAttribute attribute={filteredCampgroundAttributes[0]} />
        <CampingAttribute attribute={filteredCampgroundAttributes[1]} />
        <ReservableCampground reservable={reservation_page} />
      </div>
      <div className={styles.attributeRow}>
        <CampingAttribute attribute={filteredCampgroundAttributes[2]} />
        <CampingAttribute attribute={filteredCampgroundAttributes[3]} />
        <CampingAttribute attribute={filteredCampgroundAttributes[4]} />
      </div>
      <div className={styles.attributeRow}>
        <CampingAttributeWithExternalLink
          icon="directions"
          link={google_map_link}
          text="Directions"
        />
        <CampingAttributeWithInternalLink
          icon="youtube"
          videoId={videoId}
          link={`/videos`}
          text="Watch Video Tour"
        />
        <CampingAttributeWithInternalLink
          icon="information"
          link={`/information/${slug}`}
          text="More Information"
        />
      </div>
    </div>
  )
}

NearbyCampingFourColumn.propTypes = {
  nearbyCampingData: PropTypes.object,
}

export default NearbyCampingFourColumn
