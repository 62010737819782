import React, { useContext } from 'react'
import BlogContext from '../../contexts/blogData/blogData'
import BlogPost from '../blogPost/BlogPost'
import CampingContext from '../../contexts/campingData/campingData'
import { getFluidImage } from '../../helpers/dynamicGatsbyImage'
import { graphql, useStaticQuery } from 'gatsby'
import LodgingContext from '../../contexts/lodgingData/lodgingData'
import { orderBy } from 'lodash'
import Title from '../sharedComponents/Title/Title'
import styles from './recentPosts.module.css'

const RecentPosts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { ne: "md" } }) {
          nodes {
            childImageSharp {
              id
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
            extension
          }
        }
      }
    `
  )
  const recentPosts = orderBy(
    [
      ...useContext(BlogContext),
      ...useContext(CampingContext),
      ...useContext(LodgingContext),
    ],
    ['date'],
    ['desc']
  ).filter((recentPost, index) => {
    if (index !== 0 && index < 4) {
      return recentPost
    }
  })

  const posts = recentPosts.map((post, index) => {
    const fluidImg = getFluidImage(post, data.allFile.nodes)
    return <BlogPost hero={fluidImg} key={index} post={post} />
  })
  return (
    <div className={styles.recentPosts}>
      <Title title="Other Recent Posts" /> {posts}
    </div>
  )
}

export default RecentPosts
